import React, { useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { CopyToClipboard } from "react-copy-to-clipboard"

const quote = [
  "Cinta itu keikhlasan, tak ada paksaan ataupun rasa pelampiasan.",
  "Cinta tidak berupa tatapan satu sama lain, tetapi memandang ke luar bersama ke arah yang sama.",
  "Kegagalan hanya terjadi bila kita menyerah.",
  "Ketika seseorang menghina Anda, itu adalah sebuah pujian bahwa selama ini mereka menghabiskan banyak waktu untuk memikirkan Anda, bahkan ketika Anda tidak memikirkan mereka.",
  "Tak perlu yang sempurna, cukup temukan orang yang selalu membuat Anda bahagia dan berarti lebih dari siapapun.",
  "Salah satu kunci kebahagiaan adalah menggunakan uang Anda untuk pengalaman bukan untuk keinginan.",
  "Saya tidak bisa berjanji untuk menjadi istri yang baik. Tapi saya berjanji akan selalu mendampingi Anda.",
  "Hiduplah seperti Anda akan mati besok. Dan berbahagialah seperti Anda akan hidup selamanya.",
  "Kekurangan terhadap pasangan menjadi sesuatu yang membuat hubungan seharusnya lebih melengkapi.",
  "Harus konsisten dalam menekuni bidang disiplin ilmu yang Anda pelajari. Anda bisa menjadi konsisten seperti saya.",
  "Masa lalu saya adalah milik saya, masa lalu Anda adalah milik Anda, tapi masa depan adalah milik kita.",
  "Tanpa cinta, kecerdasan itu berbahaya. dan tanpa kecerdasan, cinta itu tidak cukup.",
  "Walaupun raga telah terpisahkan oleh kematian, namun cinta sejati tetap akan tersimpan secara abadi di relung hati.",
  "Nafsu hanya akan membawa kebahagiaan sesaat. Tetapi, cinta yang tulus dan sejati akan memberikan kebahagiaan yang sebenarnya.",
]

export default function QuoteGenerator() {
  const [quoteToCopy, setQuote] = useState()
  function handleClick(quote) {
    console.log(quote)
    document.execCommand("copy")
  }
  return (
    <React.Fragment>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop
        emulateTouch
        dynamicHeight
      >
        {quote.map((quote, index) => {
          return (
            <div className="bg-white flex flex-col justify-center h-full">
              <h2 className="text-xl font-display italic lg:text-4xl text-center px-12">
                {quote}
              </h2>
              <div className="py-2" />
              <CopyToClipboard text={`${quote} By BJ Habibie`}>
                <button
                  className="block mx-auto"
                  onClick={() => setQuote(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-12 h-12 md:w-8 md:h-8"
                  >
                    <path
                      class="heroicon-ui"
                      d="M17 7h2.25c.97 0 1.75.78 1.75 1.75v10.5c0 .97-.78 1.75-1.75 1.75H8.75C7.78 21 7 20.22 7 19.25V17H4.75C3.78 17 3 16.22 3 15.25V4.75C3 3.78 3.78 3 4.75 3h10.5c.97 0 1.75.78 1.75 1.75V7zm-2 0V5H5v10h2V8.75C7 7.78 7.78 7 8.75 7H15zM9 9v10h10V9H9z"
                    />
                  </svg>
                </button>
              </CopyToClipboard>
              {quoteToCopy === index && (
                <p className="text-green-600 mt-4">Copied!</p>
              )}
            </div>
          )
        })}
      </Carousel>
    </React.Fragment>
  )
}
