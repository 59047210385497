import React from "react"
import { Carousel } from "react-responsive-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CarouselComponent = () => {
  const { images } = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativeDirectory: { eq: "carousel" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(cropFocus: NORTH) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <div className="relative w-full aspect-ratio-square md:aspect-ratio-4/3 lg:aspect-ratio-desktop">
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          useKeyboardArrows
          emulateTouch
          className="absolute h-full inset-0"
        >
          {images.edges.map(image => {
            return (
              <div
                key={`${image.node.id}`}
                className="relative w-full aspect-ratio-square md:aspect-ratio-4/3 lg:aspect-ratio-desktop"
              >
                <Img
                  fluid={image.node.childImageSharp.fluid}
                  className="absolute h-full inset-0"
                />
              </div>
            )
          })}
        </Carousel>
        <h1 className="font-display text-2xl md:text-4xl lg:text-6xl absolute h-full inset-0 text-white flex justify-center items-center">
          In Memoriam of BJ Habibie
        </h1>
      </div>
    </React.Fragment>
  )
}

export default CarouselComponent
