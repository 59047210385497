import React from "react"
import SEO from "../components/seo"
import CarouselComponent from "../components/carousel"
import QuoteGenerator from "../components/quoteGenerator"

export default function IndexPage() {
  return (
    <React.Fragment>
      <SEO title="Welcome" />
      <div className="h-1/2 xl:h-screen">
        <CarouselComponent />
      </div>
      <div className="container px-4 lg:w-1/2 mx-auto display mt-20">
        <p className="text-center text-2xl">
          Mari kita mengenang beliau dengan membagikan quotenya yang inspiratif
        </p>
      </div>
      <div className="md:my-10 my-10 lg:my-20 w-screen lg:w-2/3 mx-auto">
        <QuoteGenerator />
      </div>
      <p className="text-center my-20">
        Built with{" "}
        <a
          href="https://sawirstudio.com"
          className="hover:text-blue-500"
          target="_blank"
          rel="noopener noreferrer"
        >
          SawirStudio
        </a>
      </p>
    </React.Fragment>
  )
}
